a {
    text-decoration: none;
}

li {
    list-style: none;
}

.hover-link {
    cursor: pointer;
}

.sidebar{
    display: none;
}
.site-layout{
    padding: 0 auto;
}

.nav-image {
    width: 100%;
  }
.section1 {
    background-color: #FFFFFF;
    // height: 220px;
    padding-top: 4vh;
    padding-bottom: 4vh;
        .section1-header {
            .news-clamp {
                cursor: pointer;
                color: #444444;
                line-height: 26px;
                padding-top: 32px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6;
                overflow: hidden;
            }
            .stokt-box:hover{
                .news-clamp{
                    color:#3c8cff;
                }
                
            }
            .stokt-box {
                // max-width: 450px;
                // padding: 0 30px;
                font-size: 16px;
                box-sizing: border-box;

                .stoke-name {
                    padding: 0;
                    margin: 0;
                    font-size: 16px;
                    font-family: pingfang;
                    color: #565b6c;
                }
            }

            .vertical-line {
                width: 2px;
                height: 27px;
                background-color: #f6f6f6;
                float: left;
                top: 50%;
                position: relative;
                transform: translateY(-50%);
            }

            .fisrtNews-box {
                text-overflow: ellipsis;
                // display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;

                a {
                    text-decoration: none;

                    h3 {
                        margin: 0;
                        font-size: 20px;
                        color: #444444;
                        font-weight: bold;
                    }

                    p {
                        font-size: 30px;
                        color: #eaeaea;
                        margin-top: 2px;
                        margin-bottom: 10px;
                        font-family: dinAlternate;
                    }

                    span {
                        font-size: 16px;
                        color: #444444;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        line-height: 26px;
                    }
                }

            }

            .newsList-box {
                // max-width: 430px;
                padding: 0 30px;
                // float: right;

                .news-header {
                    float: left;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #3c8cff;
                }

                .show-more {
                    float: right;
                    cursor: pointer;

                    a {
                        color: #3e3e3e;
                    }
                }

                ul {
                    // bottom: 0;
                    // position: absolute;
                    width: 100%;
                    padding: 39px 0 0 0 !important;

                    li {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        a {
                            color: #444444;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
}

.section2 {
    text-align: left;
    overflow: hidden;
    background-color: #F3F6FB;
    padding: 40px 0;
    background-image: linear-gradient(88deg, #fafbfd 0%, #f3f6fb 100%);

    a {
        overflow: hidden;
    }
        a {
            text-decoration: none;

            .linkcentent {
                margin: 20px 0 0 25px;
            }

            .colony-content {
                float: left;
                width: 350px;

                h4 {
                    font-size: 20px;
                    color: #3c8cff;
                    cursor: pointer;
                    margin-bottom: 0;
                }

                p {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 16px;
                    letter-spacing: 0px;
                    color: #eaeaea;
                    margin-bottom: 20px;
                }

                span {
                    line-height: 26px;
                    color: #444444;
                }
            }
        }

        .mix-duoyuan-box {
            // float: right;

            div {
                display: flex;
                overflow: hidden;

                .leftFloat {
                    float: left;
                }

                .mix-duoyuan-item {
                    width: 50%;
                    // height: 174px;
                    background-repeat: no-repeat;
                    // margin-top: 5px;
                    // background-color: #fff;
                    // margin-left: 5px;
                    position: relative;
                    // padding-bottom: 20px;
                   
                    a {
                        width: 100%;
                        height: 100%;
                        display: block;
                        transition: all .3s;
                        .shadow {
                            background-image: url(../../assets/images/home_01mask.png);
                            position: absolute;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            opacity: 0;
                            transition: all .3s;
            
                        }
                        .shadow:hover {
                            opacity: 1;
                            filter: alpha(opacity=100);
                        }
                        .icon {
                            background-image: url(../../assets/images/logistics.png);
                            background-repeat: no-repeat;
                            height: 55px;
                        }


                        .cn {
                            font-size: 30px;
                            font-family: pingfang !important;
                            font-size: 16px;
                            color: #444444;
                            margin-top: 5px;
                        }

                        .en {
                            font-family: enFamily;
                            width: 240px;
                            height: 33px;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            line-height: 16px;
                            letter-spacing: 0px;
                            color: #c9c9c9;
                            margin-top: 5px;
                        }
                        
                        .enter {
                            background-image: url(../../assets/images/arrow_icon.png);
                            height: 25px;
                            width: 28px;
                            display: block;
                            float: right;
                            position: absolute;
                            right: 100px;
                            top: 50%;
                            opacity: 0;
                            transition-delay: .3s;
                            transition: all .3s;
                        }
                    }

                    a:hover {
                        // padding-top: 10px;
                        background-color: #b7d1f5;

                        .icon {
                            background-image: url(../../assets/images/logisticsed.png);
                        }

                        .enter {
                            opacity: 1;
                            right: 50px;
                        }

                        .cn {
                            color: #FFF;
                        }
                    }

                    a:focus,
                    a:hover,
                    a:active {
                        outline: 0;
                        text-decoration: none;
                    }
                }
            }
        }
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-layout,
:where(.css-dev-only-do-not-override-10ed4xt).ant-layout * {
    box-sizing: border-box;
}

        a {
            // position: relative;
            // float: left;
            // margin-right: 5px;

            img {
                max-width: 100%;
                vertical-align: middle;
                height: auto;
            }

            .msg {
                left: 25px;
                position: absolute;
                top: 95px;

                p {
                    padding: 0;
                    margin: 0;
                }

                .cn {
                    color: #fff;
                    font-size: 18px;
                }
            }

            

            

            a:nth-child(2) .msg {
                color: #fff;
                top: 55px;
                text-align: center;
                width: 100%;
            }
        }

@media (max-width: 992px) {

    .mix-duoyuan-box {

        // display: flex;
    }
    .mix-duoyuan-item {
        // width: 100%;
        // height: 150px !important;
    }
    .nav-image {
        height: 15vh !important;
        width: 100vw !important;
        overflow: hidden;
      }
    .main-container {
        position: relative;
      }
      .sidebar-closed .sidebar{
        display: none;
      }
      .sidebar {
        display: block;
        position: fixed;
        top: 0;
        left: -220px;
        width: 35%;
        height: 100vh;
        background-color: #F9F7F3;
        transition: left 0.3s ease;
      }
      
      .page-content {
        transition: margin-left 0.3s ease;
      }
      
      .sidebar-open .page-content {
        margin-left: 35%;
        overflow: hidden;
      }
      .enter{
        display: none !important;
      }
}