.link-with-animation {
    display: inline-block;
    margin: 15px;
    transition: transform 0.3s ease-in-out;
    color: #444444;
    border: none;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .link-with-animation:hover {
    transform: scale(1.1);
    color: #40a9ff;
  }