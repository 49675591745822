.footer-link{
    font-size: 16px;
    background-color:#F4F4F4;
    .clearfix {
        clear: both;
    }
        .foot-wx{
            height: 59px;
            float: left;
            padding: 0;
            position: relative;
            display: flex;
            span {
                vertical-align: middle;
                margin-right: 10px;
                line-height: 59px;
            }
            .foot-wx-icon {
                position: relative;
                margin-top: 10px;
                display: inline-block;
                vertical-align: middle;
                .ant-image{
                    top: -120px;
                    left: -15px;
                }
                .wx-icon {
                    background-image: url(../../assets/images/wxbackground.png);
                    height: 39px;
                    width: 39px;
                    
                }
                .wx-icon:hover {
                    background-image: url(../../assets/images/wxbackground01.png);
                }
                .ant-image-img {
                    vertical-align: middle;
                    max-width: 100%;
                    height: auto;
                }
                
            }
        }
        .footerFloat {
            :focus-visible {
                outline: -webkit-focus-ring-color auto 1px;
            }
            float: right;
            height: 59px;
            border-right: 5px solid #fff;
            border-left: 5px solid #fff;
            position: relative;
            box-sizing: border-box;
            .link {
                padding: 0 10px;
                transform: translateY(18px);
                cursor: pointer;
                display: flex;
                line-height: 29px;
                justify-content: space-between;

                img {
                    float: right;
                }
            }
            ul {
                position: absolute;
                bottom: 0;
                // display: none;
                background: #f4f4f4;
                padding: 10px 0px 10px 10px;
                z-index: 99999;
                width: 100%;
                right: 0px;
                box-sizing: border-box;
                li {
                    padding-top: 5px;
                    cursor: pointer;
                    a {
                        color: #444444;
                    }
                }
            }
        }
        
        .footerFloat:last-child {
            border-right: none;
        }
}
.footer-law {
    font-family: "pingfang";
    
    padding: 20px 0;
    p {
        padding: 2px 0;
        font-size: 14px;
        margin: 1px 0;
        a {
            color: #444444;
        }
    }
}