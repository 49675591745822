a {
    text-decoration: none;
}
p {
    padding: 0;
    margin: 0;
}
.module-content{
    text-align: left;
   min-height: 250px;
   border-right: 1px solid #eaeaea;
   @keyframes fadeUp {
    0% {
     opacity: 0; 
     transform: translate3d(0, 40%, 0);
    }
    100% {
    opacity: 1; 
        transform: none; 
    }
   }
   .fadeUp {
    animation: fadeUp 2s;
    .title-en {
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 0px;
        color: #f1eff1;
        width: 220px;
        margin: 20px 0;
        font-family: enFamily;
    }
    .title-cn {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #3c8cff;
    }
}
}
.news-detail{
    text-align: left;
    min-height: 500px;
    ul li {
        margin-top: 35px;
        cursor: pointer;
        li:first-child .news-date {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ddc8ab;
            width: 100%;
        }
        .news-date {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ddc8ab;
            float: left;
            width: 135px;
            font-family: dinAlternate;
        }
        p {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ddc8ac;
        }
        div {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #444444;
            line-height: 26px;
            .news-title {
                margin-top: 10px;
                font-size: 16px;
                color: #444444;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                font-weight: 700;
                padding-left: 135px;
            }
            .news-content {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #444444;
                padding-left: 135px;
                margin-top: 10px;
                line-height: 26px;
            }
        }

    }
}
@media (max-width: 992px) {
    .news-content {
        padding-left: 0 !important;
        margin-top: 0 !important;
    }
    .news-date {
        width: auto !important;
        padding-right: 3vw;
    }
    .ul-left{
        padding-left: 0 !important;
    }

}