.sidebar{
  display: none;
}
@media (max-width: 992px) {
    .main-container {
        position: relative;
      }
      .sidebar-closed .sidebar{
        display: none;
      }
      .sidebar {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 35%;
        height: 100%;
        background-color: #F9F7F3;
        transition: left 0.3s ease;
      }
      
      .page-content {
        transition: margin-left 0.3s ease;
      }
      
      .sidebar-open .page-content {
        margin-left: 35%;
      }
}