body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 微软雅黑, "Microsoft YaHei", sans-serif;
  padding: 0px;
  overflow: auto;
}
.ant-layout{
  background-color: #FFFFFF;
  font-family: 微软雅黑, "Microsoft YaHei", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

