
   
        .cu-l {
            border-right: 1px solid #eaeaea;
            padding-top: 3vh;
            div {
                display: inline-block;
            }
            .cu-title {
                text-align: left;
                font-family: enFamily;
                font-size: 48px;
                letter-spacing: 0px;
                color: #f1eff1;
            }
            img {
                max-width: 100%;
                vertical-align: middle;
                height: auto;
            }
        }
        .cu-r {
            padding-top: 3vh;
            padding-left: 3vw;
            padding-bottom: 3vh;
            float: right;
            p:first-child {
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #0063fb;
            }
            p {
                font-size: 16px;
                letter-spacing: 0px;
                color: #444444;
                margin-bottom: 10px;
            }
            i {
                background-image: url(../../assets/images/conten_02.png);
                position: absolute;
                width: 36px;
                height: 32px;
                transition: all .3s;
                opacity: 1;
                transform: translateY(-60%);
                background-repeat: no-repeat;
                bottom: 0;
                animation: heart .6s infinite;
            }
            // ::-webkit-scrollbar {
            //     width: 7px;
            //     height: 16px;
            //     background-color: #F5F5F5;
            // }
            // ::-webkit-scrollbar-thumb {
            //     border-radius: 5px;
            //     -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            //     background: #ddc8ab;
            // }
            @keyframes heart {
                0% {
                    transform: translate(0, -6px);
                }
                100% {
                    transform: translate(0, 0);
                }
            }
        }

    @media (max-width: 992px) {

        /* 手机端样式，设置轮播图宽度为100% */
       .arrow{
        display: none;
       }
    }