a {
    text-decoration: none;
}
.page-content{
    .a-left-content {
        p {
            margin-bottom: 10px;
            line-height: 26px;
            text-align: justify;
        }
        // width: 800px;
        // display: inline-block;
        border-right: 1px solid #eaeaea;
        .date {
            margin-top: 50px;
            font-family: dinAlternate;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #f1eff1;
        }
        .en {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
        }
        .cn {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #3c8cff;
        }
        .msg {
            margin: 30px 100px 0 0;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            p {
                margin-bottom: 10px;
                line-height: 26px;
                text-align: justify;
            }
        }
        
    }
    .a-right-content {
        // width: 400px;
        // float: right;
        text-align: center;
        // height: 450px;
        p {
            padding: 0;
            margin: 0;
        }
        .msg {
            display: inline-block;
            text-align: left;
            position: relative;
            margin-top: 50px;
            margin-left: 50px;
            margin-right: 50px;
            .cn {
                font-size: 16px;
                letter-spacing: 0px;
                color: #96908b;
            }
            .en {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #96908b;
            }
            .gdName {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #676767;
            }
        }
        
    }
}
@media (max-width: 992px) {

    /* 手机端样式，设置轮播图宽度为100% */
    .a-left-content {
        border: none !important;
        .msg{
            margin: 0 !important;
        }
    }
    .a-right-content .msg{
        margin: 3vh 0 !important;
    }
    .date{
        margin-top: 3vh !important;
        font-size: 30px !important;
    }
}