a {
    text-decoration: none;
}
p {
    padding: 0;
    margin: 0;
}
.page-content {
    .center-content {
        text-align: center;
        .en {
            font-family: enFamily;
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #f1eff1;
            margin-top: 3vh;
        }
        .cn {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #0063fb;
            margin-bottom: 3vh;
        }
        img {
            max-width: 100%;
            vertical-align: middle;
            height: auto;
        }
    }
    .ant-timeline{
        .ant-timeline-item {
            padding-bottom: 60px;
            font-size: 16px;
            .ant-timeline-item-content{
                top: -35px;
                span{
                    font-size: 47px;
                }
            }
        }
    }
}