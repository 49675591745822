a {
    text-decoration: none;
}
.page-content{
    .a-left-other {
        p {
            margin-bottom: 10px;
            line-height: 26px;
            text-align: justify;
        }
        // width: 800px;
        // display: inline-block;
        border-right: 1px solid #eaeaea;
        .en {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
        }
        .cn {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #3c8cff;
        }
        .msg {
            font-family: pingfang;
            margin: 3vh 70px 0 0;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            p {
                margin-bottom: 10px;
                line-height: 2;
                text-align: justify;
            }
        }
        
    }
    .a-right-other {
        // width: 400px;
        // float: right;
        text-align: center;
        // height: 450px;
        p {
            padding: 0;
            margin: 0;
        }
        .cn {
            padding-left: 3vh;
            text-align: left;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #3c8cff;
        }
        .en {
            text-align: left;
            padding-left: 3vh;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
        }
        .msg {
            display: inline-block;
            text-align: left;
            font-family: pingfang;
            font-size: 16px;
            position: relative;
            margin-left: 50px;
            margin-top: 3vh;
            p{
                line-height: 2;
            }
        }
        
    }
    .a-bottom-other {
        // width: 400px;
        // float: right;
        text-align: center;
        // height: 450px;
        p {
            padding: 0;
            margin: 0;
        }
        .cn {
            margin-top: 3vh;
            // text-align: left;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #3c8cff;
        }
        .en {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
        }
        .msg {
            font-family: pingfang;
            display: inline-block;
            text-align: left;
            line-height: 25px;
            font-size: 16px;
            position: relative;
            margin-top: 3vh;
            margin-bottom: 3vh;
            p{
                line-height: 2;
            }
        }
        
    }
}
@media (max-width: 992px) {

    /* 手机端样式，设置轮播图宽度为100% */
    .a-left-other {
        border: none !important;
        .msg{
            margin: 0 !important;
        }
        .cn{
            font-size: 20px;
        }
        
    }
    .a-left-other .msg{
        margin-top: 1vh !important;
    }
    .date{
        margin-top: 3vh !important;
        font-size: 30px !important;
    }
    .a-right-other {
        .cn {
            padding-left: 0 !important;
        }
        .en {
            padding-left: 0 !important;
        }
        .msg {
            margin-left: 0 !important;
            margin-top: 1vh !important;
        }
    }
    .a-bottom-other {
        .cn {
            text-align: left;
        }
        .en {
            text-align: left;
        }
        .msg {
            margin-top: 1vh !important;
        }
        
    }
}